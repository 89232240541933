import * as React from "react"
import { Link } from 'gatsby';
import { useEffect, useState } from "react";
import useHttp from '../../../hooks/use-http';
import { getGSheetsEvents } from '../../../api/GSheetsEvents';
import { formatInTimeZone } from 'date-fns-tz';
import LoadingSpinner from '../../../components/ui/LoadingSpinner';
import Layout from "../../../templates/layout-standard"
import Seo from "../../../components/seo"
import SideBar from '../../../components/sidebar';
import SpeakWithAdvisor from '../../../components/ui/SpeakWithAdvisor';

const MeetAttendSeminarDetail = ({ props, location }) => {

	let { sendRequest, status, data, error } = useHttp(getGSheetsEvents, true);

	const currentPath = location.pathname;
	const params = new URLSearchParams(location.search);
	const eventSlug = params.get("event");

	const pageData = {
		slug: eventSlug
	}

	useEffect (() => {
		sendRequest(pageData);
	},[]);

	const [formloaderloaded, setFormLoaderLoaded] = useState(false);
	const [wstrackingloaded, setWsTrackingLoaded] = useState(false);

	useEffect (() => {
		if (status == 'completed' && data && data.length) {
			window.MsCrmMkt = undefined;
			window.MsCrmFormLoader = undefined;
			if (data[0][11]) {
				const script = typeof document !== `undefined` ? document.createElement("script") : null;
				script.src = "https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=" + data[0][11];
				script.async = false;
				script.id = "msformloader";
				script.addEventListener('load', () => setFormLoaderLoaded(true));
				if (document.getElementById("msformloader")) {
					document.getElementById("msformloader").remove();
				}

				if (!document.getElementById("msformloader")) {
					document.body.appendChild(script);
				}

				const trackingScript = typeof document !== `undefined` ? document.createElement("script") : null;
				trackingScript.src = "https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=" + data[0][11];
				trackingScript.async = false;
				trackingScript.id = "wstracking";
				trackingScript.addEventListener('load', () => setWsTrackingLoaded(true));
				if (!document.getElementById("wstracking")) document.body.appendChild(trackingScript);
			}
		}
	},[status]);

	let eventDate = new Date();
	
	if (status === 'pending') {
		return (
			<div><LoadingSpinner /></div>
		);
	} else if (error !== null) {
		return <p>Error: {error}</p>
	} else if (status === 'completed') {
		if (!data || data.length === 0) {
			return <p>The selected event was not found.</p>
		} 
	}

	const regLinkText = (text) => {
		return text ? text : 'Register';
	}

	return (
		<Layout location={location} heading1="Meet With Us" heading2="We&rsquo;re here to help.">
			<Seo title="Attend a Free Medicare Seminar" meta={[{description: 'Get the latest information so you can make confident decisions about your coverage.'}]} bodyclass="meet-with-us attend-a-seminar subpage" />
			
			<div className="constrained">
				<div className="content-constrained">
					<div className="flexwrap_subpage">
					
						<main>
							<Link to="/meet-with-us/attend-a-seminar/">&lt; Return to Seminars</Link>
							{ error && <h4>This event could not be found. Please return to the <Link to="/meet-with-us/attend-a-seminar/">Seminars</Link> page.</h4> }
							{data && data.map((event) => {
								
								const eventDateTime = event[1] + " " + event[2];
								const eventDate = new Date(eventDateTime);
								
								return (
								<div key={event[15]} id="eventDetails">
									<h3>{event[0]}</h3>
									{eventDate && <p>{formatInTimeZone(eventDate, 'America/New_York', 'MMMM d, yyyy, h:mma')}</p>}
									{event[3] && <p dangerouslySetInnerHTML={{ __html: event[3] }}></p>}

									<p>
									{event[8] && <span>Venue:<br/>{event[8]}<br/></span>}
									{event[9] && <span>{event[9]}</span>}
									</p>

									{event[6] && <p><a href={event[6]} target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={{ __html: regLinkText(event[5]) }}></a></p>}
									{(event[10]) && <h4>{event[10]}</h4>}
									{(event[12]) && <div data-form-block-id={event[12]}></div>}
									{(event[13]) && <div id={event[13]}></div>}
									{(event[14] && event[15]) && <div className="d365-mkt-config" style={{display:'none'}} data-website-id={event[14]} data-hostname={event[15]}></div>}
								</div>
								)
							})}
							<p className="disclaimer">UVM Health Advantage is aware that safety guidelines from federal and state agencies regarding COVID-19 have been evolving. At this time, face masks are not required while on UVM property. Disposable masks will be made available if needed. Please refer to <a href="CDC.gov" className="external">CDC.gov</a> for the latest information. </p>

							<SpeakWithAdvisor />
						</main>

						<SideBar location={location} />
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default MeetAttendSeminarDetail
